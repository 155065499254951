.bar .progress-line.reactjs span {
    width: 85%;
}
.progress-line.reactjs span::after {
    content: "85%";
}

.bar .progress-line.nodejs span {
    width: 80%;
}
.progress-line.nodejs span::after {
    content: "80%";
}
.bar .progress-line.mongodb span {
    width: 70%;
}
.progress-line.mongodb span::after {
    content: "70%";
}
.bar .progress-line.web3js span {
    width: 70%;
}
.progress-line.web3js span::after {
    content: "70%";
}

.bar .progress-line.rust span {
    width: 62%;
}
.progress-line.rust span::after {
    content: "62%";
}
.bar .progress-line.solidity span {
    width: 57%;
}
.progress-line.solidity span::after {
    content: "57%";
}

.bar .progress-line.php span {
    width: 85%;
}
.progress-line.php span::after {
    content: "85%";
}
.bar .progress-line.Javascript span {
    width: 85%;
}
.progress-line.Javascript span::after {
    content: "85%";
}
.bar .progress-line.jquery span {
    width: 78%;
}
.progress-line.jquery span::after {
    content: "78%";
}